<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-12 17:10:36
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-13 15:14:24
-->
<template>
    <div class="sceneDataReport">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">全景数据报表</div>
        </div>
        <div class="case-wrap select-wrap">
            <div class="title">
                <div class="title-wrap">
                    <div class="tag"></div>
                    <div class="title">数据汇总</div>
                </div>
            </div>
            <div class="content-wrap" v-loading="loading1">
                <div class="item-wrap">
                    <div class="item">
                        <span>{{basicData.count}}</span>全景总数
                    </div>
                    <div class="item">
                        <span>{{basicData.use_count}}</span>全景使用量
                    </div>
                </div>
                <div class="echart-item">
                    <div id="typeGrades" style="width: 100%;height: 350px;"></div>
                </div>
                <div class="echart-item">
                    <div id="functionGrades" style="width: 100%;height: 350px;"></div>
                </div>
                <div class="echart-item">
                    <div id="styleGrades" style="width: 100%;height: 350px;"></div>
                </div>
            </div>
        </div>
        <div class="case-wrap line-content">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">全景趋势</div>
            </div>
            <div class="echart" v-loading="loading2">
                <div class="line-wrap">
                    <div class="screen-wrap">
                        <commonSelect
                            @changeDate="changeDate"
                            @clickTagDate="clickTagDate"
                        ></commonSelect>
                    </div>
                    <div id="sceneGrades" style="width: 100%;height: 350px;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {panoramicTypePieEcharts,functionPieEcharts,stylePieEcharts} from "@/echarts/pieEchartData";
import {sceneLineEcharts} from "@/echarts/lineEchartData";
export default {
    components: {
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'sceneDataReport',
            loading1: false,
            loading2: false,
            basicData: {},
            dateType: '',
            startDate: '',
            endDate: '',
        }
    },
    mounted () {
        this.getSceneDataReport();
        this.getScenePieChart();
        this.getLineDataReport();
    },
    methods: {
        // 数据汇总
        getSceneDataReport(){
            common.connect("/customerservicev1/data_report/sceneDataReport",{},(res)=>{
                this.basicData = res.data
            });
        },
        // 饼图
        getScenePieChart(){
            this.loading1 = true;
            common.connect("/customerservicev1/data_report/sceneDataReportPieChart",{},(res)=>{
                panoramicTypePieEcharts('typeGrades',res.data.scene);
                functionPieEcharts('functionGrades',res.data.function);
                stylePieEcharts('styleGrades',res.data.style);
                this.loading1 = false;
            });
        },
        // 获取趋势数据
        getLineDataReport(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect("/customerservicev1/data_report/sceneDataReportLineChart",params,(res)=>{
                sceneLineEcharts('sceneGrades',res.data);
                this.loading2 = false;
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getLineDataReport();
        },
        clickTagDate(item){
            this.dateType = item.label;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getLineDataReport();
            }
        },
    },  
}
</script>

<style lang='scss'>
.sceneDataReport{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 1px 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    .select-wrap{
        padding-bottom: 20px;
        .top-select{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .select{
                .sele{
                    margin-right: 20px;
                }
            }
            .iconfont{
                font-size: 26px;
                cursor: pointer;
            }
        }
        
        .title{
            margin: 10px 0;
        }
        .content-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-wrap{
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .item{
                width: 100%;
                border: 1px solid #ddd;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: baseline;
                height: 100px;
                line-height: 100px;
                margin-bottom: 20px;
                span{
                    font-size: 26px;
                    font-weight: bold;
                    color: #000000;
                    margin-right: 10px;
                }
            }
            .echart-item{
                width: 30%;
            }
        }
    }
    .line-content{
        .title-wrap{
            padding-top: 15px;
        }
        .screen-wrap{
            padding: 20px 0;
        }
    }
}
</style>
